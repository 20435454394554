const SEO = [
	{
		page: "home",
		description:
			"I am a full-stack web developer and DSA enthusiast with expertise in MERN stack and Java Spring Boot. I have experience in building scalable, secure, and reliable web applications.",
		keywords: ["Soumik Saha", "Soumik", "Full-stack developer", "Web developer", "DSA enthusiast"],
	},

	{
		page: "about",
		description:
			"I am Soumik Saha, a final-year student from BPPIMT, Kolkata. I am currently interning at Infosys as a Java Developer. I enjoy solving complex problems and learning new skills.",
		keywords: ["Soumik Saha", "Soumik", "BPPIMT", "Java Developer", "Infosys"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Soumik Saha", "Soumik", "Programming", "Leadership", "Product design"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years, including web applications. Many of my projects are open-source and available for others to explore and contribute to.",
		keywords: ["Soumik Saha", "Soumik", "Projects", "Open-source", "Web applications"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Soumik Saha", "Soumik", "Contact", "Collaboration", "Web developer"],
	},
];

export default SEO;
