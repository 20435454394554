const INFO = {
	main: {
		title: "Portfolio - Soumik Saha",
		name: "Soumik Saha",
		email: "sahasoumik1573@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		twitter: "https://twitter.com/soumikisonline",
		github: "https://github.com/soumik-saha",
		linkedin: "https://linkedin.com/in/soumikisonline",
		instagram: "https://instagram.com/soumikisonline",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/soumikisonline",
		leetcode: "https://leetcode.com/soumik_saha/",
		geeksforgeeks: "https://auth.geeksforgeeks.org/user/sahasoumik1573/",
	},

	homepage: {
		title: "Full-stack web developer and DSA enthusiast.",
		description:
			"I am a full-stack web developer with expertise in multiple programming languages and frameworks. I have experience in building scalable, secure, and reliable web applications. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "I’m Soumik Saha, aspiring to innovate through technology.",
		description: "I am currently interning at Infosys as a Java Developer. I've worked on a variety of projects over the years, including web applications. Many of my projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},


	articles: {
		title: "I'm passionate about pushing the boundaries of technology and inspiring innovation.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "PulseConnect",
			description: "An innovative platform for connecting healthcare providers with patients. It streamlines appointment scheduling, patient management, and provides a communication channel for healthcare professionals.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/soumik-saha/pulseconnect",
			githubLink: "https://github.com/soumik-saha/pulseconnect",
			deployedLink: "fdsg", // Add deployed link if available
		},
		{
			title: "Flight Management System",
			description: "A comprehensive flight management system that includes user authentication, flight scheduling, airport management, and booking modules.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png",
			linkText: "View Project",
			link: "https://github.com/soumik-saha/flight-management-system",
			githubLink: "https://github.com/soumik-saha/flight-management-system",
			deployedLink: "", // Add deployed link if available
		},
		{
			title: "SkyPulse",
			description: "Developed for AIRBUS AEROTHON 6.0, SkyPulse automates aircraft damage assessment, faulty wire identification, and repair recommendations using OpenCV, YOLOv5, and Random Forest Classifier. Implemented as a full-stack web application with Streamlit.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/soumik-saha/SkyPulse",
			githubLink: "https://github.com/soumik-saha/SkyPulse",
			deployedLink: "", // Add deployed link if available
		},
		{
			title: "LearnHub",
			description: "LearnHub is a MERN stack application that provides a platform for users to register, log in, view a list of courses, and enroll in courses. It uses React.js for the frontend, Node.js for the backend, and MongoDB for data storage.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/soumik-saha/LearnHub",
			githubLink: "https://github.com/soumik-saha/LearnHub",
			deployedLink: "", // Add deployed link if available
		},
		{
			title: "EliteTask Dynamics",
			description: "A task management system designed to improve productivity and task tracking for teams and individuals. Features include task assignment, progress tracking, and collaboration tools.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com/soumik-saha/elitetask-dynamics",
			githubLink: "https://github.com/soumik-saha/elitetask-dynamics",
			deployedLink: "", // Add deployed link if available
		},
		{
			title: "Book Store",
			description: "Developed an advanced Bookstore Automation System using the MERN Stack, optimizing and simplifying bookstore operations. The system integrates React.js for the frontend, Node.js and Express.js for the backend, and MongoDB for efficient data management.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/soumik-saha/Book-Store",
			githubLink: "https://github.com/soumik-saha/Book-Store",
			deployedLink: "", // Add deployed link if available
		},
	],


	skills: {
		title: "Technical Skills",
		description: `
			Programming Languages: C++, Java, Python, JavaScript, HTML, CSS
			Libraries / Frameworks: React.js, Express.js, Node.js, Spring Boot, Bootstrap, Tailwind CSS
			Tools / Platforms: Git, GitHub, Postman, Linux, Windows
			Databases: MySQL, MongoDB
		`,
	},

	experience: {
		title: "Experience",
		description: `
			**Java Developer Intern** @ Infosys (May 2024 - Present)
			- Developing and maintaining Java-based applications.
			- Collaborating with cross-functional teams to define, design, and ship new features.
			- Writing efficient, reusable, and reliable Java code.

			**Managed Networking Expert** @ Chegg Inc.
			- Provided solutions and guidance on networking topics to students and professionals.
			- Assisted in solving complex networking problems.

			**Achievements:**
			- Won the "30DC Hackathon Challenge" by making PulseConnect.
			- Secured the 1st Position in the "League of Errors" coding competition at Budge Budge Institute of Technology, hosted by Coders’ Club in collaboration with Coding Ninjas.
			- Ranked 4th at the institute level on GeeksForGeeks.
			- Achieved a Global Rank of 1560 in TCS CODEVITA Season 11 Round 1 (out of 136,054 participants) and 1387 in Round 2 (out of 6,093 participants).
			- Attained a peak contest rating of 1813 on LeetCode, placing in the top 7.15% of participants.
			- Achieved a 3-star coder status on CodeChef with a maximum rating of 1609.
			- Attained the 7th rank in "Smart Coder 2.23", organized by the Technical Forum of our college.
			- Obtained the 11th Position in "Codebee" event at our college's technical fest.
			- Earned a Global Rank of 329 in the Starters 130 Division 3 contest on CodeChef.
			- Cleared Round 1 and shortlisted for Round 2 (Finale) of AIRBUS AEROTHON 6.0 with the "SkyPulse" project.
			- Solved over 2000 problems on various competitive programming platforms.
		`,
	},
};

export default INFO;
